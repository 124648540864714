.GamePage {
  background-color: black;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.inPage {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.inPageContainer {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center; /* 수평 정렬 */
  color: white;
  font-weight: 500;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: #000;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* transform 적용을 위한 기준점 */
  transform-origin: center center;
  /* 기본 크기 설정 */
  width: 1920px;
  height: 1080px;
  padding-top: 0; /* 상단 여백 제거 */
  padding-bottom: 0; /* 하단 여백 제거 */
}
.top {
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 0; /* 하단 여백 제거 */
}
.videos {
  flex: 1; /* 남은 공간 모두 차지 */
  position: relative;
  height: 100%;
}
.infos {
  width: 598px;
  height: 620px;
  position: relative; /* 자식 absolute 기준점 */
  background: url(/public/bgInfo.png) no-repeat 0 0;
}
.logNumber {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  gap: 10px;
}

.hannlogo {
  background-image: url(/public/top/info/hannlogo.png);
  width: 135px;
  height: 67px;
}
.maximlogo {
  background-image: url(/public/top/info/maximlogo.png);
  width: 135px;
  height: 67px;
}
.nustarlogo {
  background-image: url(/public/top/info/nustarlogo.png);
  width: 135px;
  height: 67px;
}
.okuralogo {
  background: url(/public/top/info/okuralogo.png) no-repeat center center;
  background-size: contain;
  width: 67px;
  height: 67px;
}
.logNumber .title {
  color: #529891;
  text-shadow: 0 0 4px #000;
  font-size: 20px;
}
.logNumber .arrow {
  background: url(/public/arrow_white_bottom.png) no-repeat center center;
  width: 20px;
  height: 20px;
  right: 50px;
  position: absolute;
}
.Number {
  padding-left: 6px;
  color: #a0ceca;
  text-shadow: 0 0 4px #000;
  font-weight: bold;
  font-size: 20px;
}

.notice {
  top: 90px;
  left: 40px;
  width: 518px;
  border-radius: 6px;
  background: #062320 url(/public/top/speaker.png) no-repeat 10px 13px;
  border: 1px solid #0e3e3a;
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.6), 0 0 20px rgba(0, 0, 0, 0.8);
  margin: 20px 40px;
}
.notice .marquee {
  display: block;
  overflow: hidden;
  margin: 0 8%;
  width: 84%;
  color: #fff;
  line-height: 43px;
  font-size: 13px;
  white-space: nowrap;
  position: relative;
}

.notice .marquee span {
  display: inline-block;
  padding-left: 100%; /* Start the text outside of the visible area */
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px 0; */
  font-size: 28px;
  width: 87%;
  border-radius: 6px;
  border: 2px solid #14786f;
  background-color: #021f1d;
  box-shadow: 0 0 4px #000;
  margin: 20px auto;
  color: white;
}

.bet-limits {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.limits-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.limit-header {
  font-weight: bold;

  text-align: start;
  padding-bottom: 5px;
}

.limit-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.limit-value {
  font-weight: bold;
  margin-left: 20px;
}

/* Text colors */
.txt-blue {
  color: #5298ff;
}
.txt-yellow {
  color: #ffcc00;
}
.txt-green {
  color: #00cc66;
}
.txt-skyblue {
  color: #00ccff;
}
.txt-pink {
  color: #ff66cc;
}
.quick-btn {
  margin-top: 10px;
  display: table;
  width: 100%;
  /* position: absolute;
    width: 500px;
    top: 580px;
    right: 50px; */
}
.move,
.fullscreen,
.exit,
.re {
  display: table-cell;
  width: 24%;
  padding-top: 10px;
}
.sys-quick-btn {
  width: 100%;
  margin-top: 10px;
  display: table;
  position: absolute;
  width: 500px;
  top: 470px;
  right: 50px;
}
.quick-btn button {
  width: 98%;
  height: 90px;
  background-image: url(/public/top/quick-btn/game-icon.png);
  background-repeat: no-repeat;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
}
.move button {
  background-color: #395794;
  border: 1px solid #263254;
  background-position: 53px -154px;
}
.fullscreen button {
  background-color: #2a958a;
  border: 1px solid #266d63;
  background-position: 53px -370px;
}
.exit button {
  background-color: #9737cf;
  border: 1px solid #7f36aa;
  background-position: 53px -295px;
}
.re button {
  background-color: #585858;
  border: 1px solid #585858;
  background-position: 53px -600px;
}
.t_btn_table_move,
.t_btn_full,
.t_btn_prev {
  position: relative;
  top: 20px;
}

.rooms {
  display: grid;
  width: fit-content;
  margin-top: 0; /* 상단 여백 제거 */
  margin-bottom: 0; /* 하단 여백 제거 */
}
/* board 내부에 두 개의 그리드를 나란히 배치 */
.mid {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0; /* 상단 여백 제거 */
  margin-bottom: 0; /* 하단 여백 제거 */
}

.board {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 상단 정렬로 변경 */
  width: fit-content;
  border: none; /* 테두리 제거 */
  background-color: #f5f5f5; /* 배경색 추가 */
  box-shadow: none; /* 그림자 효과 제거 */
  overflow: hidden; /* 내부 요소가 넘치지 않도록 */
}

.cell {
  background-color: white;
  border: 1px solid lightgray; /* 원래 색상으로 복원 */
  box-sizing: border-box;
  background-size: cover;
  color: black;
  font-size: 8px;
  margin: 0;
  padding: 0;
}
/* 왼쪽 그리드: 6x6 셀 */
.Pool-left {
  position: relative;
  width: 450px;
}

.fixed-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 178px;
  height: 29px;
  background: url("/public/gun1/title.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.scrollable {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(18, 40px);
  grid-template-rows: repeat(6, 40px);
}

.scrollable::-webkit-scrollbar {
  display: none;
}
.Pool-left .b1,
.red {
  background-image: url("/public/Pool/b1.png");
}
.Pool-left .b2,
.redP {
  background-image: url("/public/Pool/b2.png");
}
.Pool-left .b3 {
  background-image: url("/public/Pool/b3.png");
}
.Pool-left .b4 {
  background-image: url("/public/Pool/b4.png");
}
.Pool-left .p1,
.blue {
  background-image: url("/public/Pool/p1.png");
}
.Pool-left .p2 {
  background-image: url("/public/Pool/p2.png");
}
.Pool-left .p3,
.blueP {
  background-image: url("/public/Pool/p3.png");
}
.Pool-left .p4 {
  background-image: url("/public/Pool/p4.png");
}
.Pool-left .t1,
.green {
  background-image: url("/public/Pool/t1.png");
}
.Pool-left .t2 {
  background-image: url("/public/Pool/t2.png");
}
.Pool-left .t3 {
  background-image: url("/public/Pool/t3.png");
}
.Pool-left .t4 {
  background-image: url("/public/Pool/t4.png");
}
/* 오른쪽 그리드: 18x12 셀 */
.Gun-right {
  display: grid;
  grid-template-rows: auto auto; /* Three rows: gun1, gun2, gun34 */
  border-left: none; /* 왼쪽 보더 제거하여 중복 방지 */
}
.Gun1-container {
  position: relative;
  width: 1470px;
}
.fixed-overlay-gun1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 178px;
  height: 29px;
  background: url("/public/gun1/scoreboard_txt4.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.scrollable-gun1 {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(114, 20px);
  grid-template-rows: repeat(6, 20px);
  border-bottom: none; /* 구분선 제거 */
}
.gun1::-webkit-scrollbar {
  display: none;
}
.gun234 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 동일한 너비의 3개 열 */
  border-top: none; /* 상단 보더 제거하여 중복 방지 */
}
.Gun2-container {
  position: relative;
  width: 490px;
}
.fixed-overlay-gun2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 178px;
  height: 29px;
  background: url("/public/gun1/scoreboard_txt2.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.scrollable-gun2 {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(19, 40px);
  grid-template-rows: repeat(3, 40px);
  border: none; /* 기본 보더 제거 */
}
.gun2::-webkit-scrollbar {
  display: none;
}

/* 
   */
.Gun3-container {
  position: relative;
  width: 490px;
}
.fixed-overlay-gun3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 178px;
  height: 29px;
  background: url("/public/gun1/scoreboard_txt3.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.scrollable-gun3 {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(19, 40px);
  grid-template-rows: repeat(3, 40px);
  border: none; /* 기본 보더 제거 */
}
.gun3::-webkit-scrollbar {
  display: none;
}
/*  */
.Gun4-container {
  position: relative;
  width: 490px;
}
.fixed-overlay-gun4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 178px;
  height: 29px;
  background: url("/public/gun1/scoreboard_txt1.png") no-repeat center center;
  background-size: contain;
  pointer-events: none;
}

.scrollable-gun4 {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(19, 40px);
  grid-template-rows: repeat(3, 40px);
  border: none; /* 기본 보더 제거 */
}
.gun4::-webkit-scrollbar {
  display: none;
}

/* 그리드 간의 구분선 스타일 */
.gun2 {
  border-right: none; /* 구분선 제거 */
}

.gun3 {
  border-right: none; /* 구분선 제거 */
}

.gun1 > .b1 {
  background-image: url("/public/gun1/circle_b1.png");
}
.gun1 > .b1t {
  background-image: url("/public/gun1/circle_b1t.png");
}
.gun1 > .b2 {
  background-image: url("/public/gun1/circle_b2.png");
}
.gun1 > .b2t {
  background-image: url("/public/gun1/circle_b2t.png");
}
.gun1 > .b3 {
  background-image: url("/public/gun1/circle_b3.png");
}
.gun1 > .b3t {
  background-image: url("/public/gun1/circle_b3t.png");
}
.gun1 > .b4 {
  background-image: url("/public/gun1/circle_b4.png");
}
.gun1 > .b4t {
  background-image: url("/public/gun1/circle_b4t.png");
}
.gun1 > .r1 {
  background-image: url("/public/gun1/circle_r1.png");
}
.gun1 > .r1t {
  background-image: url("/public/gun1/circle_r1t.png");
}
.gun1 > .r2 {
  background-image: url("/public/gun1/circle_r2.png");
}
.gun1 > .r2t {
  background-image: url("/public/gun1/circle_r2t.png");
}
.gun1 > .r3 {
  background-image: url("/public/gun1/circle_r3.png");
}
.gun1 > .r3t {
  background-image: url("/public/gun1/circle_r3t.png");
}
.gun1 > .r4 {
  background-image: url("/public/gun1/circle_r4.png");
}
.gun1 > .r4t {
  background-image: url("/public/gun1/circle_r4t.png");
}

.gun2 > .cell {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}
.gun2 > .cell > *,
.g2Player,
.g2Banker {
  border-radius: 50%;
}
.gun2 > .cell > .b,
.g2Player {
  border: 2px solid #0042ff;
}
.gun2 > .cell > .r,
.g2Banker {
  border: 2px solid #e71b1b;
}
.gun3 > .cell {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}
.gun3 > .cell > *,
.g3Player,
.g3Banker {
  border-radius: 50%;
}
.gun3 > .cell > .b,
.g3Player {
  border: 2px solid #0042ff;
  background-color: #0042ff;
}
.gun3 > .cell > .r,
.g3Banker {
  border: 2px solid #e71b1b;
  background-color: #e71b1b;
}
.gun4 > .cell {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}
.gun4 > .cell > *,
.g4Player,
.g4Banker {
  width: 3px;
  height: 15px;
  transform: rotate(45deg);
}
.gun4 > .cell > .b,
.g4Player {
  background-color: #0042ff;
}
.gun4 > .cell > .r,
.g4Banker {
  background-color: #e71b1b;
}
.bottom {
  display: grid;
  grid-template-columns: 60% 40%;
  color: black;
  font-size: 1.5rem;
  margin-top: 0; /* 상단 여백 제거 */
  margin-bottom: 0; /* 하단 여백 제거 */
  /* transform: scale(calc(1 / var(--scale))); */
  transform-origin: bottom left;
  will-change: transform;
  width: 100%;
  height: 67px;
}

.bottom > .left {
  background-color: #e4e4e4;
  display: flex; /* 내부를 flex 컨테이너로 전환 */
  align-items: center; /* 수직 정렬을 위쪽으로 */

  gap: 0.5vmin; /* 아이콘과 텍스트 사이 간격 */
}
.left .icon {
  width: 2.7vw; /* 또는 픽셀 단위로 고정 */
  height: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.left .score {
  display: flex;
  align-items: center;
  justify-content: center;
  /* 필요에 따라 padding이나 margin 추가 */
  font-size: 1.5rem;
  /* width를 자동으로 결정하거나 고정값 사용 */
  width: auto;
}
.bottom > .right {
  background-color: gray;
  display: grid;
  grid-template-columns: 50% 50%;
}

.right button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #d4d4d4;
}
.right button div {
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  height: 40%;
  width: auto;
  aspect-ratio: 1; /* 이미지의 자연 비율에 맞게 수정 */
  background-size: contain; /* 이미지가 요소 안에 꽉 차도록 */
  background-repeat: no-repeat;
  background-position: center;
}
.right > button > .g4Player {
  width: 3px;
  height: 15px;
}
.right > button > .g4Banker {
  width: 3px;
  height: 15px;
}
.gun4 > .cell > .r,
.gun4 > .cell > .b {
  position: relative; /* left 속성을 사용하려면 필요 */
  left: 5px;
}
/* Table Move CSS */
.MoveTableList {
  position: fixed;
  right: 0px;
  top: 464px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3;
  width: 650px;
  height: 927px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.MoveTableList .titleS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  flex-shrink: 0;
}

.mRooms {
  overflow-y: auto;
  height: calc(100% - 40px);
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #026b63 #111;
}

.mRooms::-webkit-scrollbar {
  width: 8px;
}

.mRooms::-webkit-scrollbar-track {
  background: #111;
}

.mRooms::-webkit-scrollbar-thumb {
  background-color: #026b63;
  border-radius: 10px;
}

.mRooms .title {
  display: flex;
  align-items: center;
  padding: 5px;
  flex-shrink: 0;
}

.mRooms .title .logo {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin-right: 10px;
  border-radius: 50%;
}

.mRooms .title .roomInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mRooms .title .roomInfo .Number {
  font-weight: bold;
  color: #fff;
  font-size: 14px;
}

.mRooms .title .roomInfo .status {
  color: #0f0;
  font-size: 12px;
}

.mRooms > div {
  border-bottom: 1px solid #333;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.mRooms .gun1 {
  width: 600px;
  display: grid;
  grid-template-columns: repeat(57, 14px);
  grid-template-rows: repeat(6, 14px);
  overflow: hidden;
  margin: 10px 0;
}

/* Result Section */
.Result {
  position: absolute;
  width: 100%;
  height: 216.25px;
  bottom: 0;

  div {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .dummy {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 1;
  }
  .container {
    height: 100%;
    width: 100%;
    margin: auto;
    top: -40%;
    z-index: 2;
  }

  .stick {
    display: flex;
    justify-content: center; /* Places PScore at the left, BScore at the right */
    align-items: center;
    background-image: url(/public/result/result.png);
    top: 0;
    width: 1010px;
    height: 276px;
  }

  .PScore,
  .BScore {
    font-size: 2rem; /* Example font size */
    top: 8%;
  }
  .PScore {
    padding-right: 325px;
  }
  .BScore {
    padding-left: 325px;
  }
  .winPlayer,
  .winBanker,
  .winTie {
    height: 50%;
    width: 100%;
    top: 100px;
  }
  .winPlayer {
    background-image: url(/public/result/r_player.png);
  }
  .winBanker {
    background-image: url(/public/result/r_banker.png);
  }
  .winTie {
    background-image: url(/public/result/r_tie.png);
  }
  .Player {
    position: relative;
    height: 118px;
    width: fit-content;
    display: grid;
    grid-auto-flow: column;
    transform: translateX(-450px) translateY(100px);
    gap: 10px;

    div {
      position: relative;
      background-image: url(/public/result/poker.png);
      background-size: 1115px 510px;
      width: 80px;
      height: 118px;
    }
    .Pcard2 {
      grid-column: 1;
      transform: rotate(90deg) translateY(70px) translateX(22px);
    }

    .Pcard0 {
      grid-column: 2;
    }

    .Pcard1 {
      grid-column: 3;
    }
  }
  .Banker {
    position: relative;
    height: 118px;
    width: fit-content;
    display: grid;
    grid-auto-flow: column;
    transform: translateX(200px);
    gap: 10px;

    div {
      position: relative;
      background-image: url(/public/result/poker.png);
      background-size: 1115px 510px;
      width: 80px;
      height: 118px;
    }
    .Bcard0 {
      grid-column: 1;
    }
    .Bcard1 {
      grid-column: 2;
    }
    .Bcard2 {
      grid-column: 3;
      transform: rotate(90deg) translateY(10px) translateX(22px);
    }
  }
  .dia1 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: 2px -390px;
  }
  .dia2 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -80px -390px;
  }
  .dia3 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -158px -390px;
  }
  .dia4 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -238px -390px;
  }
  .dia5 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -318px -390px;
  }
  .dia6 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -398px -390px;
  }
  .dia7 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -478px -390px;
  }
  .dia8 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -558px -390px;
  }
  .dia9 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -638px -390px;
  }
  .dia10 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -718px -390px;
  }
  .dia11 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -798px -390px;
  }
  .dia12 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -878px -390px;
  }
  .dia13 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -958px -390px;
  }
  .clover1 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: 2px -261px;
  }
  .clover2 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -80px -261px;
  }
  .clover3 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -158px -261px;
  }
  .clover4 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -238px -261px;
  }
  .clover5 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -318px -261px;
  }
  .clover6 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -398px -261px;
  }
  .clover7 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -478px -261px;
  }
  .clover8 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -558px -261px;
  }
  .clover9 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -638px -261px;
  }
  .clover10 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -718px -261px;
  }
  .clover11 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -798px -261px;
  }
  .clover12 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -878px -261px;
  }
  .clover13 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -958px -261px;
  }
  .heart1 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: 2px -132px;
  }
  .heart2 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -80px -132px;
  }
  .heart3 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -158px -132px;
  }
  .heart4 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -238px -132px;
  }
  .heart5 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -318px -132px;
  }
  .heart6 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -398px -132px;
  }
  .heart7 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -478px -132px;
  }
  .heart8 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -558px -132px;
  }
  .heart9 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -638px -132px;
  }
  .heart10 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -718px -132px;
  }
  .heart11 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -798px -132px;
  }
  .heart12 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -878px -132px;
  }
  .heart13 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -958px -132px;
  }
  .spade1 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: 2px -3px;
  }
  .spade2 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -80px -3px;
  }
  .spade3 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -158px -3px;
  }
  .spade4 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -238px -3px;
  }
  .spade5 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -318px -3px;
  }
  .spade6 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -398px -3px;
  }
  .spade7 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -478px -3px;
  }
  .spade8 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -558px -3px;
  }
  .spade9 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -638px -3px;
  }
  .spade10 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -718px -3px;
  }
  .spade11 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -798px -3px;
  }
  .spade12 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -878px -3px;
  }
  .spade13 {
    background-size: 1115px 510px; /* 스프라이트 전체 크기를 줄이거나 늘려서 사용 */
    background-position: -958px -3px;
  }
}

/* 반응형 화면 조정을 위한 스타일 */
@media (max-aspect-ratio: 16/9) {
  .App {
    align-items: center;
    justify-content: center;
  }
}

@media (min-aspect-ratio: 16/9) {
  .App {
    align-items: center;
    justify-content: center;
  }
}

/* 4:3 화면 비율에서의 스타일 */
@media (aspect-ratio: 4/3) {
  .App {
    align-items: center;
    justify-content: center;
  }
}

/* 화면 크기에 따른 기본 스타일 */
@media (max-width: 1366px) {
  html,
  body,
  .App {
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  html,
  body,
  .App {
    overflow: hidden;
  }
}

@media (max-width: 800px) {
  html,
  body,
  .App {
    overflow: hidden;
  }
}
