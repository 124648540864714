.LoginPage {
  width: 100%;
  height: 100vh;
  background: url(./bg.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  font-family: sans-serif;

  .form_container {
    width: 320px;
    height: 450px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    position: relative;
  }

  @media screen and (max-width: 767px) {
    .form_container {
      max-width: 90%;
    }
  }

  .form_container .img-box {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    top: -45px;
    transform: translateX(-50%);
    border-radius: 50%;
  }

  .form_container .img-box img {
    width: 100%;
    height: 100%;
  }

  .form_container h3 {
    margin: 50px auto;
    font-size: 25px;
    color: #ddd;
    letter-spacing: 0.7px;
  }

  .input-field {
    margin-bottom: 17px;
  }

  .input-field input {
    width: 100%;
    padding: 15px 0;
    color: #ddd;
    border: none;
    outline: none;
    background: transparent;
    font-size: 15px;
    border-bottom: 1px solid #f39c12;
  }

  .input-field input[type="submit"] {
    background: transparent;
    border: 1.5px solid #f39c12;
    border-radius: 25px;
    font-size: 1em;
    letter-spacing: 0.7px;
    font-weight: 600;
    color: #ddd;
    cursor: pointer;
    transition: 0.3s;
  }

  .input-field input[type="submit"]:hover {
    background-color: #f39c12;
    color: #111;
  }

  button {
    width: 100%;
    padding: 15px 0;
    background: transparent;
    border: 1.5px solid #f39c12;
    border-radius: 25px;
    font-size: 1em;
    letter-spacing: 0.7px;
    font-weight: 600;
    color: #ddd;
    cursor: pointer;
    transition: 0.3s;
  }

  .input-field button:hover {
    background-color: #f39c12;
    color: #111;
  }

  .input-field:nth-child(3) {
    margin-bottom: 35px;
  }

  .input-field p {
    text-align: left;
    margin-bottom: 15px;
  }

  .input-field p a {
    text-decoration: none;
    color: #888;
    font-size: 13px;
    transition: 0.3s;
  }

  .input-field p a:hover {
    color: #f39c12;
  }
  .input-field em {
    position: absolute;
    margin: 10px 0 0 -50px;
    font-weight: bold;
    color: #fff;
  }

  .language_class {
    margin: 50px 0 20px 0;
    position: relative;
  }
  .language_class button {
    position: relative;
    width: 100%;
    padding: 10px 0px 10px 18px;
    border: 1px solid #666666;
    color: #fff;
    line-height: 1em;
    font-size: 1.1em;
    text-align: left;
    box-sizing: border-box;
  }
  .language_class button img {
    height: 20px;
  }
  .language_class button span {
    position: absolute;
    right: 10px;
    top: 50%;
    text-align: center;
    font-size: 10px;
    width: 0;
    height: 0;
    margin: -1px auto 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #fff;
    z-index: 1;
  }
  .language_class div.select {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -1px;
    z-index: 2;
    background: #0a0a0a;
    border: 1px solid #666;
    box-sizing: border-box;
    border-top: 0;
    color: #fff;
    text-align: left;
    cursor: pointer;
  }
  .language_class div.select img {
    height: 20px;
  }
  .language_class div.select div {
    padding: 8px;
    border-top: 1px solid #666;
  }
  .language_class div.select div:hover {
    background: #000;
  }
  .language_class div.select.active {
    display: block;
  }

  .agree_title {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .form_container2 {
    width: 800px;
    height: 600px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    position: relative;
    color: #fff;
  }
  .form_container2_box {
    width: 99%;
    height: 75%;
    overflow: auto;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    position: relative;
    color: #fff;
  }

  @media screen and (max-width: 767px) {
    .form_container2 {
      max-width: 90%;
    }
  }

  .form_container2 .img-box {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    top: -45px;
    transform: translateX(-50%);
    border-radius: 50%;
  }

  .form_container2 .img-box img {
    width: 100%;
    height: 100%;
  }

  .form_container2 h3 {
    margin: 50px auto;
    font-size: 25px;
    color: #ddd;
    letter-spacing: 0.7px;
  }

  .i_btn {
    margin-top: 20px;
  }
  .i_btn div:first-child {
    float: left;
    width: 48%;
  }
  .i_btn div:last-child {
    float: right;
    width: 48%;
  }

  /*  */

  .form_container2 {
    width: 800px;
    height: 600px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    position: relative;
    color: #fff;
  }
  .agree_title {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .form_container2_box {
    width: 95%;
    height: 75%;
    overflow: auto;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    position: relative;
    color: #fff;
  }
}
.inPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
