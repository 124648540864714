@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.LobbyPage {
  background: url(/public/patternbg.png) repeat 0 0;
  width: 100vw;
  height: 100vh;
}
.inPage {
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -384px 0 0 -683px;
  width: 1366px;
  height: 768px;
  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    vertical-align: baseline;
    text-align: justify;
    font-size: 100%;
    font-family: Nanum Gothic, Arial, dotum, "sans-serif";
    font-style: normal;
    font-size: 12px;
  }
  input,
  li,
  a,
  span {
    text-decoration: none;
    outline-style: none;
    font-weight: normal;
  }
  .header {
    background: url(/public/header/bg_room_top_line.png);
    position: relative;
    height: 70px;
  }
  .header::before {
    left: 0;
    background: url(/public/header/bg_room_top_left.png);
    content: "";
    position: absolute;
    top: 0;
    width: 5px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  .header::after {
    right: 0;
    background-image: url(/public/header/bg_room_top_right.png);
    content: "";
    position: absolute;
    top: 0;
    width: 5px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: 0 0;
  }

  .game-type {
    position: absolute;
    top: 10px;
    left: 0;
    width: 140px;
    text-align: center;

    img {
      height: 50px;
      width: auto;
    }
  }
  .notice {
    position: absolute;
    top: 14px;
    left: 141px;
    display: block;
    width: 342px;
    height: 43px;
    background: url(/public/header/bg_notice.png) no-repeat 0 0;
  }
  .notice .marquee-container {
    display: inline-block;
    /* margin: 0 8%;
    width: 84%; */
    color: #fff;
    line-height: 43px;
    font-size: 13px;
  }
  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  .marquee-text {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
  }

  .user-info select {
    margin-top: -3px;
    padding: 0 4px;
    width: 124px;
    height: 30px;
    border: 1px solid #324f62;
    background-color: #10191f;
    color: #fff;
    font-size: 12px;
    margin: 0;
  }

  .user-info {
    position: absolute;
    top: 22px;
    left: 540px;
    text-align: left;
    font-size: 0;

    list-style: none;
    line-height: 12px;

    li {
      display: inline-block;
      margin-right: 50px;
      vertical-align: top;
      color: #fff;
      font-size: 15px;
    }
    i {
      display: inline-block;
      margin-right: 4px;
      width: 24px;
      height: 24px;
      vertical-align: -8px;
      background-image: url(/public/header/icon_more.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    span {
      display: inline-block;
      font-size: 15px;
    }

    select:not(:-internal-list-box):not([multiple]) option {
      min-inline-size: 24px;
      min-block-size: max(24px, 1lh);
      font-weight: -internal-auto-base(normal, inherit);
      padding-block-end: -internal-auto-base(1px, 0);
      display: -internal-auto-base(block, flex);
      align-items: center;
      padding-inline: -internal-auto-base(2px, 0.5em);
      gap: 0.5em;
    }
  }
  .quick-button {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 280px;
    li {
      display: inline-block;
      margin-left: 10px;
    }
    button {
      display: block;
      width: 80px;
      height: 31px;
      background: url(/public/header/button_bg_blue.png) no-repeat 0 0;
      box-shadow: 0 0 4px #000;
      color: #fff;
      text-shadow: 0 0 3px #000;
      text-align: center;
      line-height: 30px;
      font-size: 13px;
      font-weight: bold;
      border: none;
    }
  }

  .game-list {
    position: relative;
    padding: 2px 6px;
    height: 694px;
    background: url(/public/header/bg_roomcontent_line3.png) repeat-x 0 bottom;
  }
  .room_list .game-list:before {
    left: 0;
    background: url(/public/header/bg_roomcontent_line.png) repeat-y 0 0;
  }
  .room_list .game-list:after {
    right: 0;
    background: url(/public/header/bg_roomcontent_line2.png) repeat-y right 0;
  }
  .game-room {
    position: relative;
    height: 690px;
    z-index: 1;
    overflow-y: scroll;
    background: url(/public/header/bg_room_content.png) no-repeat 0 bottom;
    text-align: left;
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .box {
    display: inline-block;
    margin: 8px;
    vertical-align: top;
    width: 422px;
    height: 254px;
    border: 3px solid #282828;
    box-shadow: 0 0 4px #000;
    background-color: #191919;

    .type {
      position: relative;
      height: 37px;
      background: url(/public/header/bg_roombox_top.png) no-repeat center 0;

      h3 {
        padding: 10px 0 0 14px;
        color: #fff;
        font-size: 15px;
      }
      span {
        font-size: 15px;
      }
    }
  }

  .scoreboard2 {
    display: flex;

    height: 147px;
    justify-content: center;
    .cell {
      background-color: white;
      border: 1px solid lightgray;
      box-sizing: border-box;
    }

    .beadplate {
      box-sizing: border-box;
      width: 147px;
      height: 147px;
      display: grid;
      grid-template-columns: repeat(6, 24.5px);
      grid-template-rows: repeat(6, 24.5px);
      grid-auto-flow: column;
      .P,
      .B,
      .T {
        position: relative; /* Ensure parent is positioned */
      }
      .P {
        width: 100%;
        height: 100%;
        background-color: #003deb;
        border-radius: 50%;
        font-family: "Rubik";
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .B {
        width: 100%;
        height: 100%;
        background-color: #e71b1b;
        border-radius: 50%;
        font-family: "Rubik";
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .T {
        width: 100%;
        height: 100%;
        background-color: #299702;
        border-radius: 50%;
        font-family: "Rubik";
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pair2::before {
        content: "";
        width: 20%;
        height: 20%;
        background-color: #ff0000;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
      }
      .pair3::after {
        content: "";
        width: 20%;
        height: 20%;
        background-color: #0042ff;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 1;
      }
      .pair4::before {
        content: "";
        width: 20%;
        height: 20%;
        background-color: #ff0000;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
      }
      .pair4::after {
        content: "";
        width: 20%;
        height: 20%;
        background-color: #0042ff;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 1;
      }
    }
    .bigroad {
      width: 257.25px;
      height: 73.5px;
      display: grid;
      grid-template-columns: repeat(21, 12.25px);
      grid-template-rows: repeat(6, 12.25px);
      .r,
      .b,
      tie {
        width: 6.175px;
        height: 6.175px;
        border-radius: 50%;
        position: relative; /* Ensure parent is positioned */
      }
      .r {
        border: 2px solid #e71b1b;
      }
      .b {
        border: 2px solid #0042ff;
      }
      .tie {
        color: black;
        font-size: 7px;
        text-align: center;
      }
      .tie::before {
        content: "";
        position: absolute;
        bottom: -40%;
        right: 20%;
        display: block;
        width: 1px;
        height: 8px;
        background-color: #26bc00;
        transform: rotate(45deg);
      }
      .pair2::before {
        content: "";
        width: 60%;
        height: 60%;
        background-color: #e71b1b;
        border-radius: 50%;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 1;
      }
      .pair3::after {
        content: "";
        width: 60%;
        height: 60%;
        background-color: #0042ff;
        border-radius: 50%;
        position: absolute;
        bottom: -1px;
        right: -1px;
        z-index: 1;
      }
      .pair4::before {
        content: "";
        width: 60%;
        height: 60%;
        background-color: #e71b1b;
        border-radius: 50%;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 1;
      }
      .pair4::after {
        content: "";
        width: 60%;
        height: 60%;
        background-color: #0042ff;
        border-radius: 50%;
        position: absolute;
        bottom: -1px;
        right: -1px;
        z-index: 1;
      }
    }
    .smallroad {
      width: 257.25px;
      height: 36.75px;

      display: grid;
      grid-template-columns: repeat(21, 12.25px);
      grid-template-rows: repeat(3, 12.25px);

      .cell {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;

        .b {
          border: 1px solid #0042ff;
          border-radius: 50%;
        }
        .r {
          border: 1px solid #e71b1b;
          border-radius: 50%;
        }
      }
    }
    .bigeyeboy {
      height: 36.75px;

      display: grid;
      grid-template-columns: repeat(11, 12.25px);
      grid-template-rows: repeat(3, 12.25px);

      .cell {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;

        .b {
          background-color: #0042ff;
          border-radius: 50%;
        }
        .r {
          background-color: #e71b1b;
          border-radius: 50%;
        }
      }
    }
    .cockroachpig {
      height: 37.5px;

      display: grid;
      grid-template-columns: repeat(10, 12.25px);
      grid-template-rows: repeat(3, 12.25px);

      .cell {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;

        .b {
          display: block;
          width: 2px;
          height: 6px;
          background-color: #0042ff;
          transform: rotate(45deg);
        }
        .r {
          display: block;
          width: 2px;
          height: 6px;
          background-color: #e71b1b;
          transform: rotate(45deg);
        }
      }
    }
  }
  .scoreboard2 .left {
    flex: 0 0 auto; /* Fixed width as needed; beadplate width is already defined */
  }
  .scoreboard2 .right {
    /* flex: 1;
    display: flex; */
    flex-direction: column;
  }

  /* Upper section: bigroad over smallroad */
  .scoreboard2 .right .upper {
    display: flex;
    flex-direction: column;
  }

  /* Lower section: bigeyeboy and cockroachpig side by side */
  .scoreboard2 .right .lower {
    display: flex;
    flex-wrap: nowrap; /* 한 줄에 배치 */
    width: 257.25px; /* 또는 두 요소의 총 너비 이상 */
  }
  .scoreboard2 .bigeyeboy,
  .scoreboard2 .cockroachpig {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
.bpt-time {
  position: absolute;
  width: 410px;
  height: 4px;
  margin-left: 5px;
}
.bpt-info {
  position: relative;
  height: 58px;
  background: url(/public/header/bg_roomcontent_line4.png) no-repeat center top;
}
.bpt-info ul {
  position: absolute;
  top: 24px;
  left: 14px;
}
.bpt-info li {
  text-shadow: 0 0 2px #000;
}
.bpt-info li {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
}
.txt-red {
  color: #ff0000 !important;
}
.bpt-info li span {
  font-size: 20px;
  font-weight: bold;
}
.txt-blue {
  color: #4ebaff !important;
}
.bpt-info li:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  margin: 4px 8px;
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 4px;
}
.txt-green {
  color: #3bc200 !important;
}
.bpt-info .status {
  display: block;
  position: absolute;
  left: 50%;
  top: 40%;
  font-size: 20px;
  color: yellow;
  font-weight: bold;
}
.bpt-info button {
  display: block;
  position: absolute;
  top: 14px;
  right: 14px;
  width: 87px;
  height: 31px;

  box-shadow: 0 0 4px #000;
}
.enterko {
  background: url(/public/header/btn_bg_enter.png) no-repeat 0 0;
}
.enteren {
  background: url(/public/header/btn_bg_enter_en.png) no-repeat 0 0;
}
.entercn {
  background: url(/public/header/btn_bg_enter_cn.png) no-repeat 0 0;
}
.enterjp {
  background: url(/public/header/btn_bg_enter_jp.png) no-repeat 0 0;
}
